import * as React from "react"
import PropTypes from "prop-types"
import * as styles from "src/scss/_header.scss"

import Logo from "src/images/renk-logo.jpg"
import ArrowRight from "src/images/arrow-right.png"

export default function siteTitle () {
  if (typeof window !== 'undefined' ) {
    window.addEventListener( "scroll" ,function(){ // ヘッダーにスクロールしたら影をつける
      let scroll = window.pageYOffset;

      if (scroll < 20) {
        document.getElementById("header").style.boxShadow = "none"
      } else {
        document.getElementById("header").style.boxShadow = "0px 4px 10px rgba(0, 0, 0, 0.25)"
      }
    });
  }

  return (
    <header id="header">
      <div className="header-container">
        <div className="logo">
          <a href="/">
            <img src={ Logo } alt="リンクチャネル株式会社" />
          </a>
        </div>
        <input type="checkbox" id="openSideMenu" className="menu-checkbox"></input>
        <label htmlFor="openSideMenu" className="sidebarIconToggle">
          <div className="spinner diagonal part-1"></div>
          <div className="spinner horizontal"></div>
          <div className="spinner diagonal part-2"></div>
        </label>

        <nav id="sidebarMenu">
          <ul className="sidebarMenuInner">
            <li><a href="/about/">About Us<br /><span>会社概要</span></a></li>
            <li><a href="/services/">Services<br /><span>事業概要</span></a></li>
            <li><a href="/products/">Products<br /><span>製品情報</span></a></li>
            <li><a href="/recruit/">Recruit<br /><span>採用情報</span></a></li>
            <li><a href="/contact/">Contact Us<br /><span>お問い合わせ</span></a></li>
            <li><a href="/products/info-invoice/" className="sp-info">インフォ・ザ・伝票を<br />ご購入の方はコチラ</a></li>
          </ul>
        </nav>

        <nav className="nav-pc">
          <ul>
            <li><a href="/about/">About Us<br /><span>会社概要</span></a></li>
            <li><a href="/services/">Services<br /><span>事業概要</span></a></li>
            <li><a href="/products/">Products<br /><span>製品情報</span></a></li>
            <li><a href="/recruit/">Recruit<br /><span>採用情報</span></a></li>
            <li><a href="/contact/">Contact Us<br /><span>お問い合わせ</span></a></li>
            <li className="info-shop">
              <span>
                <a href="/products/info-invoice/">
                  インフォ・ザ・伝票を<br />ご購入の方はコチラ
                </a>
                <img src={ ArrowRight } alt="" />
              </span>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  )
}
