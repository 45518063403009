/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { useLocation } from "@reach/router";

import Header from "./header"
import Footer from "./footer"
// import "./layout.scss"
import "src/scss/index.scss"
import { Helmet } from "react-helmet"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const location = useLocation();
  const pathname = location.pathname;

  return (
    <>
      <Helmet>
        {/* <!-- Google Tag Manager --> */}
          <script>
            {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-MVG62KP');`}
          </script>
        {/* <!-- End Google Tag Manager --> */}
      </Helmet>
        {/* <!-- Google Tag Manager (noscript) --> */}
          <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MVG62KP"
          height="0" width="0" style={{display:'none',visibility:'hidden'}}></iframe></noscript>
        {/* <!-- End Google Tag Manager (noscript) --> */}
      {/* <Header siteTitle={data.site.siteMetadata?.title || `Title`} /> */}
      {!(pathname === "/about-lp/" || pathname === "/about-lp") && (<Header />)}
      <div
        // style={{
        //   margin: `0 auto`,
        //   maxWidth: 960,
        //   padding: `0 1.0875rem 1.45rem`,
        // }}
      >
        <main>{children}</main>
        {!(pathname === "/about-lp/" || pathname === "/about-lp") && (<Footer />)}
        {/* <footer
          style={{
            marginTop: `2rem`,
          }}
        > */}
          {/* <p>© {new Date().getFullYear()} ReNK CHANNEL Co, Ltd.</p> */}
          {/* © {new Date().getFullYear()}, Built with
          {` `} */}
          {/* <a href="https://www.gatsbyjs.com">Gatsby</a> */}
        {/* </footer> */}
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
