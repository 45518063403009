import * as React from "react"
import PropTypes from "prop-types"
import * as styles from "src/scss/_footer.scss"
import HFlogo from "src/images/hard-off-logo.png"

const Footer = ({ siteTitle }) => (
  <footer>
    <div className="footer-wrapper">
      <div className="footer-inner">
        <div className="hf-logo-web">
          <a href="https://www.hardoff.co.jp/" target="_blank" rel="noopener noreferrer">
            <img src={ HFlogo } alt="ハードオフグループ" />
          </a>
          <p>HARD OFF CORPORATION</p>
        </div>
        <ul>
          <li><a href="/about/">会社概要</a></li>
          <li><a href="/services/">事業概要</a></li>
          <li><a href="/products/">製品情報</a></li>
          <li><a href="/recruit/">採用情報</a></li>
          <li><a href="/contact/">お問い合わせ</a></li>
          <li><a href="https://www.hardoff.co.jp/privacy/" target="_blank" rel="noopener noreferrer">プライバシーポリシー</a></li>
          <li><a href="/commercial-transactions/">特定商取引法に基づく表記</a></li>
        </ul>
        <div className="hf-logo-ps">
          <a href="https://www.hardoff.co.jp/" target="_blank" rel="noopener noreferrer">
            <img src={ HFlogo } alt="ハードオフグループ" />
          </a>
          <p>HARD OFF CORPORATION</p>
        </div>
        <p className="copyrights">© 2020 ReNK CHANNEL Co., Ltd.</p>
      </div>
    </div>
  </footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
